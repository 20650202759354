import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/ArtWorkList.css';
import ApiClient from "../../base/ApiClient";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form"
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";


class CategoryItem extends React.PureComponent {
    state = {
        isEditing: false,
        isSaving: false,
        errorMessage: '',
        updatedValue: ''
    }

    handleUpdateCategoryNameChange(e) {
        const newValue = e.target.value;
        this.setState({updatedValue: newValue})
    }


    handleUpdateClick() {
        this.setState({isSaving: true, errorMessage: ''}, () => {
            this.props.api.patchItem(this.props.category.id, {"name": this.state.updatedValue}).then(
                response => {
                    this.setState({
                        isEditing: false,
                        errorMessage: '',
                        updatedValue: ''
                    }, () => {
                        this.props.onUpdate(response.data)
                    })
                }
            ).catch(error => {
                if (error && error.response && error.response.data) {
                    const errorMessage = error.response.data.name ? error.response.data.name : error.response.data.detail
                    this.setState({errorMessage: errorMessage ? errorMessage : "Someting went wrong"})
                }
                console.error(error)
            }).finally(() => {
                this.setState({isSaving: false})
            })
        })
    }

    handleCancelClick() {
        this.setState({
            isEditing: false,
            updatedValue: '',
            errorMessage: ''
        })
    }

    render() {
        const text = <>
            {this.props.isHighlighted ? <strong>{this.props.category.name}</strong> : this.props.category.name} &nbsp;
            <button className="btn btn-link" onClick={() => {this.setState({isEditing: true})}}>
                <i className="fa fa-pen" title="Edit"/>
            </button>
        </>;
        const edit = <>
            <ButtonGroup>
                <Form.Control
                    // size="lg"
                    type="text" placeholder="Category Name"
                    value={this.state.updatedValue ? this.state.updatedValue : this.props.category.name} name="name"
                    onChange={this.handleUpdateCategoryNameChange.bind(this)}
                    required
                    disabled={this.state.isSaving}
                />
                <Button type="button" disabled={this.state.isSaving || !this.state.updatedValue || this.state.updatedValue === this.props.category.name}
                        onClick={this.handleUpdateClick.bind(this)} title="Save">
                    {this.state.isSaving ? <i className="fa fa-spinner"/> : <i className="fa fa-save"/>}
                </Button>
                <Button type="button" disabled={this.state.isSaving} className="btn-danger"
                        onClick={this.handleCancelClick.bind(this)} title="Cancel">
                    <i className="fa fa-times"/>
                </Button>

            </ButtonGroup>
            <Form.Text>{this.state.errorMessage}</Form.Text>
        </>

        return <Col
            sm={6} md={4} xl={2} className="category-list-item pb-1" key={this.props.category.id}>
            <Card>
                <Card.Body>{this.state.isEditing ? edit : text}</Card.Body>
                <Card.Footer className="small">Published Tutorials: {this.props.category.tutorials_count}</Card.Footer>
            </Card>
        </Col>
    }
}


export default class CategoryList extends React.PureComponent {
    categoryApiClient;
    state = {
        categories: [],
        newCategoryName: '',
        highlightedCategory: '',
        isAdding: false,
        addingError: ''
    }

    componentDidMount() {
        this.categoryApiClient = new ApiClient('category');
        this.categoryApiClient.list({params: {page_size: 1000, has_tutorials_only: false}}).then(response => {
            this.setState({categories: [...response.data.results]})
        })
    }

    handleCategoryAdd() {
        this.setState({isAdding: true, addingError: ''}, () => {
            this.categoryApiClient.create({"name": this.state.newCategoryName}).then(response => {
                this.setState(prevState => ({
                    categories: [...prevState.categories, response.data],
                    newCategoryName: '',
                    highlightedCategory: response.data.name,
                    addingError: ''
                }))
            }).catch(error => {
                if (error && error.response && error.response.data) {
                    const errorMessage = error.response.data.name ? error.response.data.name : error.response.data.detail
                    this.setState({addingError: errorMessage ? errorMessage : "Someting went wrong"})
                }
                console.error(error)
            }).finally(() => {
                this.setState({isAdding: false})
            })
        })
    }

    handleNewCategoryNameChange(e) {
        const newName = e.target.value;
        this.setState({newCategoryName: newName});
    }

    renderCategoryList() {
        return this.state.categories.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else if (a.name < b.name) {
                return -1
            } else {
                return 0
            }
        }).map(o => <CategoryItem
            key={o.id}
            isHighlighted={this.state.highlightedCategory === o.name}
            category={o}
            api={this.categoryApiClient}
            onUpdate={(updatedItem) => {
                this.setState(prevState=>({
                    ...prevState,
                    categories: [...prevState.categories.filter(cat => cat.id !== o.id), updatedItem]
                }))
            }}
        />)
    }

    render() {
        const categoryNames = this.state.categories.map(o => o.name).sort();
        const upperCategoryNames = categoryNames.map(o => o.toUpperCase())
        const categoryList = this.renderCategoryList()

        const isNewCategoryOk = !this.state.newCategoryName ||
            upperCategoryNames.indexOf(this.state.newCategoryName.toUpperCase()) > -1;

        return <Container fluid className="min-vh-100 main-container" id="main-container">

            <Navbar variant="dark" expand="lg" sticky="top">
                <Navbar.Brand href="">Categories</Navbar.Brand>
                <NavLink className="btn draweroo-btn ml-auto mt-0" to='/'>Back</NavLink>
            </Navbar>

            <div className="main-content">
                <Row>
                    <Col><h3>Current Categories</h3></Col>
                </Row>
                <Row>
                    {categoryList.length > 0 ? categoryList : 'No categories yet!'}
                </Row>
                <Row>
                    <Col sm={6} md={4} className="m-auto pt-5">
                        <Card className="p-3">
                            <Card.Title>
                                Add New Category
                            </Card.Title>
                            <Card.Body className="m-auto">
                                <ButtonGroup>
                                    <Form.Control
                                        size="lg" type="text" placeholder="Category Name"
                                        value={this.state.newCategoryName} name="name"
                                        onChange={this.handleNewCategoryNameChange.bind(this)}
                                        required
                                        isInvalid={isNewCategoryOk}
                                    />
                                    <Button type="button" disabled={isNewCategoryOk || this.state.isAdding}
                                            onClick={this.handleCategoryAdd.bind(this)}>
                                        {this.state.isAdding ? 'Adding... ' : 'Add'}
                                    </Button>

                                </ButtonGroup>
                                <Form.Text>{this.state.addingError}</Form.Text>
                            </Card.Body>

                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    }
}