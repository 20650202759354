import Badge from "react-bootstrap/Badge";
import React from "react";
import ApiClient from "../../base/ApiClient";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormCheck from "react-bootstrap/FormCheck";
import ReactTags from "react-tag-autocomplete";
import Image from "react-bootstrap/Image";

function TagComponent({tag, removeButtonText, onDelete}) {
    return (
        <Badge pill variant="primary">{tag.name} |
            <span className="delete-tag" onClick={onDelete}
                  title={removeButtonText}> x </span>
        </Badge>
    )
}

export class TutorialEditorForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            errorMessages: {},
            suggestions: [],
            categoryChoices: [],

            coverImage: null,

            form: {
                name: '',
                categories: [],
                tags: []
            },

            tutorialId: props.tutorialId,
            isLoading: false,
            hasLoadingError: false,

            canSave: false
        }
    }

    categoryApiClient = new ApiClient('category');
    tutorialApiClient = new ApiClient('tutorial');

    componentDidMount() {
        this.loadCategories();
        if (this.props.tutorialId) {
            this.setState({tutorialId: this.props.tutorialId}, () => {
                if (this.state.tutorialId) {
                    this.loadTutorial();
                }
            })
        }
    }

    loadCategories() {
        this.categoryApiClient.list({params: {page_size: 1000, has_tutorials_only: false}}).then(response => {
            this.setState({categoryChoices: Object.values(response.data.results).map(cat => cat.name)})
        })
    }

    loadTutorial() {
        this.setState({isLoading: true}, () => {
            this.tutorialApiClient.getItem(this.state.tutorialId).then(result => {
                this.setState(prevState => ({
                    form: {
                        name: result.data.name,
                        cover: result.data.cover,
                        tags: Object.values(result.data.tags).map(tag => ({name: tag})),
                        categories: [...result.data.categories]
                    },
                    coverImage: result.data.cover,
                    suggestions: [...prevState.suggestions, ...result.data.tags],
                    hasLoadingError: false
                }))
            }).catch(error => {
                this.setState({hasLoadingError: true});
                throw error;
            }).finally(() => {
                this.setState({isLoading: false})
                this.updateCanSave()
            });
        })
    }

    handleDeleteTag(i) {
        const tags = this.state.form.tags.slice(0)
        tags.splice(i, 1)
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                tags: tags
            }
        }))
    }

    handleAddTag(tag) {
        const tags = [].concat(this.state.form.tags, tag)
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                tags: tags
            }
        }))
    }

    handleCoverUpload(event) {
        this.setState({
            coverImage: URL.createObjectURL(event.target.files[0]),
            coverImageFile: event.target.files[0]
        }, () => {
            this.updateCanSave()
        })
    }


    handleFormUpdate(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: value
            }
        }), () => {
            this.updateCanSave()
        })
    }

    handleFormCheckUpdate(event) {
        const name = event.target.name;
        const value = event.target.value;
        const isChecked = event.target.checked;
        this.setState(prevState => {
            const originalValues = this.state.form[name] ? this.state.form[name] : []
            const newValues = isChecked ? [...originalValues, value] : originalValues.filter(val => val !== value)
            return {
                ...prevState,
                form: {
                    ...prevState.form,
                    [name]: newValues
                }
            }
        }, () => {
            console.log(this.state.form)
        })
    }

    save(progressCallBack, completeCallBack, tutorialIdCallBack) {
        // chain the save commands
        this.saveMainForm(progressCallBack, () => {
            this.saveTagsAndCategories(progressCallBack, completeCallBack)
        }, tutorialIdCallBack)
    }

    saveMainForm(progressCallBack, completeCallBack, tutorialIdCallBack) {
        const _this = this;
        this.setState({showSaveProgress: true, saveProgressText: 'Processing inputs'}, () => {
            const config = {
                onUploadProgress: function (progressEvent) {
                    const thisProgress = progressEvent.loaded / progressEvent.total;
                    progressCallBack(
                        Math.round(thisProgress * 80) / 100,
                        "Uploading cover image"
                    )
                }
            }

            const data = new FormData()
            if (_this.state.coverImageFile) {
                data.append('cover', _this.state.coverImageFile)
            }
            data.append('name', _this.state.form.name)

            if (_this.state.tutorialId) {
                _this.tutorialApiClient.patch('/' + _this.state.tutorialId + '/', data, config)
                    .then(res => {
                        // TODO: save this: res.data.id
                        tutorialIdCallBack(_this.state.tutorialId)
                        completeCallBack()
                    })
                    .catch(err => console.log(err))
            } else {
                _this.tutorialApiClient.post('/', data, config)
                    .then(res => {
                        // TODO: save this: res.data.id
                        _this.setState({tutorialId: res.data.id}, () => {
                            tutorialIdCallBack(_this.state.tutorialId)
                            completeCallBack()
                        })
                    })
                    .catch(err => console.log(err))
            }
        })
    }

    saveTagsAndCategories(progressCallBack, completeCallBack) {
        const _this = this;

        const tagsAndCategoriesApiConfig = {
            onUploadProgress: function (progressEvent) {

                const thisProgress = progressEvent.loaded / progressEvent.total;
                progressCallBack(
                    Math.round(80 + (thisProgress * 20)) / 100,
                    "Saving categories and tags"
                )
            }
        };

        const tutorialUrl = '/' + _this.state.tutorialId + '/';
        _this.tutorialApiClient.patch(tutorialUrl, {
            tags: Object.values(_this.state.form.tags).map(tag => tag.name),
            categories: _this.state.form.categories
        }, tagsAndCategoriesApiConfig)
            .then(res => {
                // run a callback
                completeCallBack()
            })
            .catch(err => console.log(err))
    }

    updateCanSave() {
        console.log("return", this.state.form.name && (this.state.tutorialId || this.state.coverImageFile))
        console.log(this.state.form.name, (this.state.tutorialId || this.state.coverImageFile))
        console.log(this.state.tutorialId, this.state.coverImageFile)
        this.setState(
            {canSave: this.state.form.name && (this.state.tutorialId || this.state.coverImageFile)},
            () => {
                this.props.onChange && this.props.onChange()
            }
        )
    }

    render() {
        if (this.state.isLoading) {
            return <>Fetching artwork tutorial data...</>
        } else if (this.state.hasLoadingError) {
            return <>Something went wrong while fetching artwork tutorial data.</>
        }

        const categoryChoices = this.state.categoryChoices;


        const {tags, categories, name} = this.state.form;
        const suggestions = [...this.state.suggestions, ...tags].map((value, index) => ({id: index, name: value}));

        const buttonErrors = [];
        if (!this.state.form.name) {
            buttonErrors.push("Please provide an artwork tutorial name.")
        }
        if (!this.state.tutorialId && !this.state.coverImageFile) {
            buttonErrors.push("Please provide a cover image.")
        }


        return <>
            <Form className="mx-auto" id="tutorial-form">
                <Row>
                    <Col xs={12}>

                        <Form.Group controlId="formName">
                            <Form.Control
                                size="lg" type="text" placeholder="Artwork Name"
                                value={name} name="name"
                                // onChange={this.handleUserNameChange.bind(this)}
                                onChange={this.handleFormUpdate.bind(this)}
                                required isInvalid={!name}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={6}>
                        <Form.Group controlId="formCategories">
                            <Form.Label>Choose Categories</Form.Label>
                            <Row>
                                {categoryChoices.map(cat => <Col xs={12} sm={6} key={"col-category-" + cat}>
                                    <FormCheck
                                        name="categories"
                                        id={"category-" + cat}
                                        type="checkbox" label={cat} value={cat}
                                        checked={categories.indexOf(cat) > -1}
                                        onChange={this.handleFormCheckUpdate.bind(this)}/>
                                </Col>)}
                            </Row>
                        </Form.Group>

                        <ReactTags
                            tags={tags}
                            suggestions={suggestions}
                            onDelete={this.handleDeleteTag.bind(this)}
                            onAddition={this.handleAddTag.bind(this)}
                            allowNew={true}
                            tagComponent={TagComponent}
                            delimiters={['Enter', ',']}
                        />

                    </Col>
                    <Col lg={12} md={6}>

                        <label className="btn" id="upload-cover-photo">
                            Upload a cover photo <input type="file" hidden accept="image/*"
                                                        onChange={this.handleCoverUpload.bind(this)}/>
                        </label>
                        <Image src={this.state.coverImage} fluid className="vw-100"/>
                    </Col>
                </Row>
            </Form>

            {buttonErrors.length > 0 && buttonErrors.map(message => <p className="alert alert-danger small">
                <i className="fa fa-info-circle fa-lg" title={message}/> {message}
            </p>)}
        </>
    }

}