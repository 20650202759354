import React from 'react';
import ApiClient from "../../base/ApiClient";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/cjs/Container";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";

function snakeToTitleCase(string) {
    return string.split('_').map(word => word[0].toUpperCase() + word.slice(1)).join(" ")
}

function valueToInputType(value) {
    if (isNaN(value) || value === '') {
        return 'input'
    } else {
        return 'number'
    }
}


export default class Settings extends React.PureComponent {
    apiClient = new ApiClient('admin/settings')

    state = {
        oldSettings: {},
        newSettings: {},
        savingList: [],
        updatedList: [],
        errorMessages: {},
    }

    componentDidMount() {
        this.apiClient.getItem('latest').then(response => {
            this.setState({oldSettings: response.data, newSettings: response.data})
        })
    }

    inputChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState(prevState => ({...prevState, newSettings: {...prevState.newSettings, [key]: value}}))
    }

    handleImageUpload(e) {
        const key = e.target.name;
        const image = e.target.files[0];

        this.setState(prevState => ({...prevState, newSettings: {...prevState.newSettings, [key]: image}}))
    }

    buildData(key) {
        if (typeof this.state.newSettings[key] == 'string') {
            return {[key]: this.state.newSettings[key]}
        } else {
            const data = new FormData()
            data.append(key, this.state.newSettings[key])
            return data
        }
    }

    handleSaveClick(key) {
        this.setState(prevState => ({
            ...prevState,
            savingList: [
                ...prevState.savingList,
                key
            ]
        }), () => {
            this.apiClient.patchItem('latest', this.buildData(key)).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    oldSettings: response.data,
                    newSettings: {...prevState.newSettings, [key]: response.data[key]},
                    updatedList: [
                        ...prevState.updatedList,
                        key
                    ],
                    errorMessages: {
                        ...prevState.errorMessages,
                        [key]: null
                    }
                }), () => {
                    // after 5 seconds, remove it from the updated list
                    setTimeout(() => {
                        this.setState(prevState => ({
                            ...prevState,
                            updatedList: [
                                ...prevState.updatedList.splice(prevState.updatedList.indexOf(key), 1)
                            ]
                        }))
                    }, 5000)
                });
            }).catch(error => {
                if (error && error.response && error.response.data) {
                    this.setState(prevState => ({
                        ...prevState,
                        errorMessages: {
                            ...prevState.errorMessages,
                            [key]: error.response.data[key].join(" ")
                        }
                    }))
                }
            }).finally(() => {
                this.setState(prevState => ({
                    ...prevState,
                    savingList: [
                        ...prevState.savingList.splice(prevState.savingList.indexOf(key), 1)
                    ]
                }))
            })
        })
    }


    renderSettingInput(settingKey) {
        const inputValue = this.state.newSettings[settingKey] ? this.state.newSettings[settingKey] : '';
        const oldValue = this.state.oldSettings[settingKey] ? this.state.oldSettings[settingKey] : '';
        const isUrl = settingKey.split('_').indexOf('url') > -1 || settingKey.split('_').indexOf('link') > -1
        const oldValueDisplay = isUrl ? <a href={oldValue} rel="noopener noreferrer"  target="_blank">{oldValue}</a> : oldValue;
        const errorMessage = this.state.errorMessages[settingKey] ? this.state.errorMessages[settingKey] : '';

        const canSave = String(inputValue) === String(oldValue);
        let buttonText = 'Save'
        if (this.state.savingList.indexOf(settingKey) > -1) {
            buttonText = <>Saving...</>
        } else if (this.state.updatedList.indexOf(settingKey) > -1) {
            buttonText = <>Updated!</>
        }
        return <Col lg={6} md={12} key={settingKey}>
            <Card className="my-2">
                <Card.Body>
                    <Card.Title>{snakeToTitleCase(settingKey)}</Card.Title>

                    <small>Original: <strong>{oldValue ? oldValueDisplay : <i>Not set</i>}</strong></small>
                    <InputGroup>
                        <Form.Control
                            name={settingKey}
                            type={valueToInputType(inputValue)}
                            value={inputValue}
                            onChange={this.inputChange.bind(this)}
                        />
                        <Button
                            style={{width: "10em"}}
                            disabled={canSave}
                            onClick={this.handleSaveClick.bind(this, settingKey)}
                        >
                            {buttonText}
                        </Button>
                    </InputGroup>
                    {errorMessage && <p className="alert alert-danger small">{errorMessage}</p>}

                </Card.Body>
            </Card>
        </Col>
    }

    renderSettingImage(settingKey) {
        const inputValue = this.state.newSettings[settingKey] ? this.state.newSettings[settingKey] : '';
        const imageUrl = typeof inputValue == 'string' ? inputValue : URL.createObjectURL(inputValue);

        const oldValue = this.state.oldSettings[settingKey] ? this.state.oldSettings[settingKey] : '';
        const errorMessage = this.state.errorMessages[settingKey] ? this.state.errorMessages[settingKey] : '';

        const canSave = typeof inputValue == 'string';
        let buttonText = 'Upload'
        if (this.state.savingList.indexOf(settingKey) > -1) {
            buttonText = <>Saving...</>
        } else if (this.state.updatedList.indexOf(settingKey) > -1) {
            buttonText = <>Updated!</>
        }
        return <Col lg={6} md={12} key={settingKey}>
            <Card className="my-2">
                <Card.Body>
                    <Card.Title>{snakeToTitleCase(settingKey)}</Card.Title>

                    <Row>
                        <Col md={6} className="mb-4">
                            <small>Original: <strong>
                                {oldValue ? <a href={oldValue} rel="noopener noreferrer" target="_blank">{oldValue}</a> : <i>Not set</i>}
                            </strong></small>
                            {oldValue && <Image src={oldValue} fluid className="vw-100"/>}
                        </Col>
                        <Col md={6}>
                            <InputGroup>
                                <label className="btn form-control btn-dark">
                                    Select new {snakeToTitleCase(settingKey)} <input
                                    type="file" accept="image/*" hidden name={settingKey}
                                    onChange={this.handleImageUpload.bind(this)}
                                />
                                </label>

                                <Button
                                    style={{width: "10em"}}
                                    disabled={canSave}
                                    onClick={this.handleSaveClick.bind(this, settingKey)}
                                >
                                    {buttonText}
                                </Button>
                            </InputGroup>
                            { oldValue !== inputValue && inputValue.name}
                            { oldValue !== inputValue && <Image src={imageUrl} fluid className="vw-100"/>}

                        </Col>
                    </Row>
                    {errorMessage && <p className="alert alert-danger small">{errorMessage}</p>}

                </Card.Body>
            </Card>
        </Col>
    }

    render() {
        return <Container fluid className="min-vh-100 main-container">
            <Navbar variant="dark" expand="lg" sticky="top">
                <Navbar.Brand href="">
                    App Settings
                </Navbar.Brand>
                <NavLink className="btn draweroo-btn ml-auto mt-0" to='/'>Back</NavLink>
            </Navbar>
            <div className="main-content">
            <Row>
                {Object.keys(this.state.newSettings).map(settingKey => {
                    if (settingKey.split('_').indexOf('image') > -1) {
                        return this.renderSettingImage(settingKey)
                    } else {
                        return this.renderSettingInput(settingKey)
                    }
                })}
            </Row>
            </div>
        </Container>
    }
}