import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../css/ArtWorkList.css';
import ApiClient from "../base/ApiClient";
import ArtWorkListItem from "./ArtWorkListItem";


export default class ArtWorkList extends React.PureComponent {
    tutorialApiClient = null;
    state = {
        tutorials: [],
        isLoading: false,
        hasError: false,
        selectedContributorName: '',
        searchTerm: '',
        params: {
            page_size: 15,
        },
        currentPage: 1,
        nextUrl: '',
        totalItems: 0,
        publishedItems: 0
    }

    componentDidMount() {
        this.tutorialApiClient = new ApiClient('tutorial');
        this.loadData()
    }

    loadData(clearData) {

        this.setState(prevState =>{
            const newState = {
                ...prevState,
                isLoading: true
            };
            if (clearData) {
                newState['currentPage'] = 1;
                newState['tutorials'] = [];
            }
            return newState;
        }, () => {
            // TODO: properly handle pagination
            this.tutorialApiClient.list({params: {...this.state.params, page: this.state.currentPage}}).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    tutorials: [
                        ...prevState.tutorials,
                        ...response.data.results
                    ],
                    nextUrl: response.data.next,
                    hasError: false,
                    totalItems: response.data.count,
                    publishedItemsCount: response.data.published_count
                }))
            }).catch(error => {
                console.error(error)
                this.setState({hasError: true})
            }).finally(() => {
                this.setState({isLoading: false})
            })
        })
    }

    handleChangeParam(paramName, paramValue) {
        this.setState(prevState => ({
            ...prevState,
            params: {
                ...prevState.params, [paramName]: paramValue
            }
        }),  () => {
            this.loadData(true)
        })
    }

    handleClearParam(paramName) {
        this.setState(prevState => ({
            ...prevState,
            params: {
                ...prevState.params, [paramName]: undefined
            }
        }),  () => {
            this.loadData(true)
        })
    }
    clearParams() {

    }

    handleSelectContributor(contributorName, contributorId) {
        this.setState(prevState => ({
            ...prevState,
            selectedContributorName: contributorName,
            params: {
                ...prevState.params, created_by: contributorId
            }
        }),  () => {
            this.loadData(true)
        })
    }

    handleRemoveContributor() {
        this.setState(prevState => ({
            ...prevState,
            selectedContributorName: '',
            params: {
                ...prevState.params, created_by: undefined
            }
        }),  () => {
            this.loadData(true)
        })
    }

    handleItemUpdate(item) {
        this.setState(prevState => {
            const newTutorialList = Object.values(prevState.tutorials).map(i => i.id === item.id ? item : i)
            return {
                ...prevState,
                tutorials: [
                    ...newTutorialList
                ]
            }
        })
    }

    handleApplySearch() {
        this.setState(prevState => ({
                ...prevState,
                params: {...prevState.params, search: prevState.searchTerm}
            }),
            () => {
                this.loadData(true)
            }
        )
    }

    renderContributorName() {
        if (this.state.selectedContributorName) {
            return <Col><h3>Contributor: {this.state.selectedContributorName} <sup>
                <i className="fa fa-times-circle" style={{cursor:"pointer"}} onClick={this.handleRemoveContributor.bind(this)}/>
            </sup></h3></Col>
        }
    }
    renderCurrentSearch() {
        if (this.state.params && this.state.params.search) {
            return <Col><h3>Search: {this.state.params.search} <sup>
                <i className="fa fa-times-circle" style={{cursor:"pointer"}} onClick={() => {
                    this.setState(prevState => ({
                        ...prevState,
                        params: {...prevState.params, search: null}
                    }), () => { this.loadData(true)})
                }}/>
            </sup></h3></Col>
        }
    }

    renderLoadMore() {
        if (this.state.nextUrl) {
            return <Col
                onClick={() => {
                    if (!this.state.isLoading) {
                        this.setState(prevState => ({
                            ...prevState,
                            currentPage: prevState.currentPage + 1
                        }), () =>{
                            this.loadData(false);
                        })
                    }
                }}
                sm={12} md={6} xl={4} className="m-0 p-0 artwork-list-item" style={{cursor: "pointer"}}>
                <div className="card flex-row flex-nowrap mx-2 mt-2 mb-0 p-0">
                    <div className="card-header border-0 p-0 tutorial-cover-image"/>
                    <div className="card-block px-2 pt-2 flex-column">
                        <h5>{this.state.isLoading ? "Loading more..." : "Load More..."}</h5>
                    </div>
                </div>
            </Col>
        }
    }

    renderSearch() {
        if (this.props.isAdmin) {
            return <Col md={3}>
                <div className="input-group">
                    <input type="text" placeholder="search" className="input form-control"
                           onChange={(e) => {
                               this.setState({searchTerm: e.target.value})
                           }}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') {
                                   this.handleApplySearch()
                               } else if (e.key === 'Escape') {
                                   this.setState({searchTerm: ''})
                               }
                           }}
                           value={this.state.searchTerm}
                    />

                    <div className="input-group-append">
                        <button type="button" className="btn btn-outline-secondary" onClick={this.handleApplySearch.bind(this)}>
                            <i className="fa fa-search"/> Search
                        </button>
                    </div>
                </div>
            </Col>
        }
    }

    render() {
        const artworkList = this.state.hasError ? "Something went wrong during loading." :
            (this.state.tutorials.length === 0 && this.state.isLoading
                ? "Loading artwork tutorials..."
                : this.state.tutorials.map(o => <Col sm={12} md={6} xl={4} className="m-0 p-0 artwork-list-item"
                                                     key={o.id}>
                    <ArtWorkListItem
                        isAdmin={this.props.isAdmin}
                        tutorialData={o}
                        apiClient={this.tutorialApiClient}
                        tutorialOnChange={this.handleItemUpdate.bind(this)}
                        selectContributor={this.handleSelectContributor.bind(this)}
                    />
                </Col>)
            );
        return <div id="artwork-list" className="main-content">
            <Row>
                {this.renderContributorName()}
                {this.renderCurrentSearch()}
                <Col>
                    <h3>Total: {this.state.totalItems} &nbsp; &nbsp; Published: {this.state.publishedItemsCount}</h3>
                </Col>
                {this.renderSearch()}
            </Row>
            <Row>
                {artworkList.length > 0 ? artworkList : 'No artwork tutorials yet'}
                {this.renderLoadMore()}
            </Row>

        </div>
    }
}