import CanvasDraw from "./CanvasDraw";

export default class CanvasWithCheckpoints extends CanvasDraw {
    constructor(props) {
        const checkpoints = props.checkpoints ? props.checkpoints : [];
        super(props);

        this.state = {
            checkpoints: checkpoints,
            currentCheckpointIndex: checkpoints.length,
            temporaryLines: null
        }
    }

    saveCheckpoint() {
        this.setState(prevState => ({
            ...prevState,
            checkpoints: [...prevState.checkpoints, this.lines.length],
            currentCheckpointIndex: prevState.checkpoints.length + 1
        }), () => {
            this.triggerOnChange()
        })
    }

    canSaveCheckpoint() {
        return (this.state.checkpoints.length === 0 && this.lines.length > 0)
            || (this.state.checkpoints.length > 0 && this.lines.length > this.state.checkpoints[this.state.checkpoints.length - 1])
    }

    unsaveCheckpoint() {
        this.setState(prevState => ({
            ...prevState,
            checkpoints: [...prevState.checkpoints.slice(0, -1)],
            currentCheckpointIndex: prevState.checkpoints.length
        }), () => {
            this.triggerOnChange()
        })
    }

    nextCheckpoint() {
        this.setState(prevState => ({
            ...prevState,
            currentCheckpointIndex: prevState.checkpoints.length < prevState.currentCheckpointIndex
                ? prevState.checkpoints.length
                : prevState.currentCheckpointIndex + 1
        }), () => {
            this.loadCheckpoint(this.state.currentCheckpointIndex)
        })
    }

    previousCheckpoint() {
        this.setState(prevState => ({
            ...prevState,
            currentCheckpointIndex: prevState.currentCheckpointIndex === 0 ? 0 : prevState.currentCheckpointIndex - 1,
            temporaryLines: prevState.currentCheckpointIndex === prevState.checkpoints.length
                ? JSON.parse(JSON.stringify(this.lines))
                : prevState.temporaryLines
        }), () => {
            this.loadCheckpoint(this.state.currentCheckpointIndex)
        })
    }

    loadCheckpoint(checkpointIndex) {
        const newSaveData = JSON.stringify({
            lines: checkpointIndex >= this.state.temporaryLines.length
                ? this.state.temporaryLines
                : this.state.temporaryLines.slice(0, this.state.checkpoints[checkpointIndex]),
            width: this.props.canvasWidth,
            height: this.props.canvasHeight
        });
        this.loadSaveData(newSaveData, true)

    }

}