import Modal from "react-bootstrap/Modal";
import FormEditor from "./FormEditor";
import Button from "react-bootstrap/Button";
import React from "react";
import ApiClient from "../../base/ApiClient";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ChangePassword extends React.PureComponent {
    state = {
        showEditor: false
    }

    api = new ApiClient('me')

    constructor(props) {
        super(props);
        this.editor = React.createRef()
    }

    render() {
        return <>
            <button className="btn btn-link" onClick={() => {this.setState({showEditor: true})}} style={{cursor: "pointer"}}>
                <i className="fa fa-lock"/><span className="d-none d-sm-inline"> Change Password</span>
            </button>
            <ToastContainer style={{display: "inline"}}/>
            <Modal show={this.state.showEditor} onHide={() => {this.setState({showEditor: false})}}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormEditor
                        ref={this.editor}
                        api={this.api}
                        schema={[
                            {label: 'Old Password', name: 'old_password', type: "password"},
                            {label: 'New Password', name: 'new_password', type: "password"},
                            {label: 'Repeat Password', name: 'repeat_password', type: "password"}
                        ]}
                        hideButtons={true}
                        // onSave={this.onSave.bind(this)}
                        onCancel={() => {this.setState({showEditor: false})}}
                        onSave={() => {
                            toast.success("Password Changed!")
                            this.setState({showEditor: false});
                        }}
                        itemId={'update_password'}
                        dontLoad={true}
                        method={'PUT'}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {this.editor.current.saveData()}}>
                        Change Password
                    </Button>
                    <Button variant="secondary" onClick={() => {this.setState({showEditor: false})}}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    }
}