import React from 'react';
import '../css/Login.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Button, Form, Image} from "react-bootstrap";
import logo from "../ituroo.png";
import {login} from "../base/ApiClient";
import {withRouter} from "react-router-dom";
import {isLoggedIn} from "axios-jwt";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.history = props.history;
        this.location = props.location;
        this.state = {
            username: '',
            password: '',
            isLoggingIn: false,
            errorMessages: {},
        }
    }

    componentDidMount() {

        this.redirectIfLoggedIn()
    }

    redirectIfLoggedIn() {
        if (isLoggedIn()) {
            let { from } = this.location.state || { from: { pathname: "/" } };
            this.history.replace(from)
        }
    }

    handleDoLogin(e) {
        e.preventDefault();

        this.setState({
            isLoggingIn: true,
            errorMessage: ''
        }, () => {
            login({...this.state}).then(() => {
                this.props.onLoginSuccess();
                this.redirectIfLoggedIn()
            }).catch((error) => {
                if (error.response && typeof(error.response.data) != "string") {
                    const errorMessages = Object.keys(error.response.data).reduce((accumulator, curr) => {
                        if (typeof(error.response.data[curr]) == "string") {
                            accumulator[curr] = error.response.data[curr];
                        } else {
                            accumulator[curr] = error.response.data[curr].join(' ');
                        }
                        return accumulator;
                    }, {});

                    this.setState({
                        errorMessages: errorMessages
                    })
                } else {
                    this.setState({
                        errorMessages: {detail: "Something went wrong. Please contact administrator."}
                    })
                }
            }).finally(() => {
                this.setState({
                    isLoggingIn: false
                })
            })
        })
    }

    handleUserNameChange(e) {
        this.setState({username: e.target.value})
    }
    handlePasswordChange(e) {
        this.setState({password: e.target.value})
    }

    render() {
        const buttonContent = this.state.isLoggingIn ?
            <span className="spinner-border" role="status" aria-hidden="true"/>: "Submit";
        const formErrorMessage = this.state.errorMessages.hasOwnProperty("detail") ? <p className="text-danger">{this.state.errorMessages['detail']}</p>: null;

        return <Container fluid className="vh-100" id="login-container">
            <Row className="vh-100 justify-content-center">

                <Col id="login-col" xs="12" lg="6" xl="4" className="my-auto text-center">
                    <div id="login-container-inner">
                        <Image src={logo} fluid style={{filter: "invert(1)"}}/>
                        <h3>Contributors Dashboard</h3>
                        <Form className="mx-auto" onSubmit={this.handleDoLogin.bind(this)}
                              validated={!!this.state.errorMessages} noValidate isValid={!this.state.errorMessages} isInvalid={!!this.state.errorMessages}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control size="lg" type="text" placeholder="Username"
                                              onChange={this.handleUserNameChange.bind(this)}
                                              disabled={this.state.isLoggingIn}
                                              isInvalid={this.state.errorMessages.hasOwnProperty('username')}
                                              required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.state.errorMessages.username}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Control size="lg" type="password" placeholder="Password"
                                              onChange={this.handlePasswordChange.bind(this)}
                                              disabled={this.state.isLoggingIn}
                                              isInvalid={this.state.errorMessages.hasOwnProperty('password')}
                                              required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.state.errorMessages.password}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {formErrorMessage}

                            <Button variant="primary" type="submit" className="draweroo-btn"
                                    onClick={this.handleDoLogin.bind(this)}
                                    disabled={this.state.isLoggingIn}
                            >
                                {buttonContent}
                            </Button>

                        </Form>
                    </div>
                </Col>

            </Row>
        </Container>;
    }

}

export default withRouter(Login);
