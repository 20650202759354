import React from "react";
import Container from "react-bootstrap/Container";
import ContributorNavbar from "./elements/ContributorNavbar";
import ArtWorkList from "./ArtWorkList";

export default class ContributorDashboard extends React.PureComponent {
    render() {
        return <Container fluid className="min-vh-100 main-container" id="main-container">
            <ContributorNavbar/>
            <ArtWorkList isAdmin={this.props.isAdmin}/>
        </Container>
    }
}