import React from 'react';
import '../css/TutorialEditor.css'
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import undoImage from '../pasted image 0 (3).png';
import Image from "react-bootstrap/Image";
import Modal from 'react-bootstrap/Modal';
import ProgressBar from "react-bootstrap/ProgressBar";
import ApiClient from "../base/ApiClient";
import {withRouter} from "react-router";
import CanvasWithCheckpoints from "./elements/CanvasWithCheckpoints";
import {TutorialEditorForm} from "./elements/TutorialEditorForm";
import FormCheck from "react-bootstrap/FormCheck";


class ProgressDialog extends React.PureComponent {
    render() {
        console.log(this.props.progress);
        return <Modal show={this.props.show} onHide={this.props.handleClose}>
            <Modal.Header closeButton={!!this.props.handleClose}>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProgressBar now={this.props.progress} label={`${this.props.progress}%`}/>
                {this.props.progressText}
            </Modal.Body>
            {this.props.handleClose && <Modal.Footer>
                { this.props.additionalFooters }
                <Button variant="primary" onClick={this.props.handleClose}>
                    Ok
                </Button>
            </Modal.Footer>}
        </Modal>
    }
}

class ConfirmDialog extends React.PureComponent {
    render() {
        return <Modal show={this.props.show} onHide={this.props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.props.body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={this.props.handleConfirm}>
                    Yes, I'm sure
                </Button>
            </Modal.Footer>
        </Modal>
    }
}


class TutorialEditor extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showConfirmUndo: false,
            traceImage: null,
            isRecording: false,

            saveProgress: 0,
            saveProgressText: 'Processing...',
            showSaveProgress: false,

            tutorialId: props.match.params.tutorialId,
            isLoading: false,
            hasLoadingError: false,
            isLoadingSteps: false,
            hasLoadingStepsError: false,

            canSaveCheckpoint: false,
            canUndo: false,
            canRewindCheckpoint: false,
            canForwardCheckpoint: false,
            savedCheckpointCount: 0,
            currentCheckpointIndex: 0,

            saveData: null,
            initialCheckpoints: [],
            showCanvas: false,
            lazyRadius: 0,
        }

        this.canvasContainerRef = React.createRef()
        this.canvas = React.createRef()
        this.form = React.createRef()
    }
    tutorialApiClient;

    keyDownEventListener(event) {
        if (event.ctrlKey && event.key === 'z' && this.state.isRecording) {
            this.handleUndoClick();
        }
    }

    componentDidMount() {
        this.tutorialApiClient = new ApiClient('tutorial');

        if (this.state.tutorialId) {
            this.loadTutorialSteps()
        } else {
            this.setState({showCanvas: true})
        }

        this.setState({lazyRadius: 0})

        this.keyHandlerEventListener = document.addEventListener('keydown', this.keyDownEventListener.bind(this));
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDownEventListener);
    }

    getLazyRadius() {
        return window.screen.width > 600 ? 15 : 5;
    }

    loadTutorialSteps() {
        this.setState({isLoadingSteps: true}, () => {
            this.tutorialApiClient.get('/' + this.state.tutorialId + '/data/').then(result => {
                if (result.data.save_data) {
                    const saveData = JSON.stringify(result.data.save_data);
                    const checkpoints = result.data.checkpoints;
                    this.setState({
                        saveData: saveData,
                        initialCheckpoints: checkpoints,
                        showCanvas: true
                    })
                } else {
                    this.setState({showCanvas: true})
                }
            }).catch(error => {
                this.setState({hasLoadingStepsError: true})
                throw error;
            }).finally(() => {
                this.setState({isLoadingSteps: false})
            })
        })
    }

    handleCloseProgress() {
        this.setState({showSaveProgress: false})
    }

    handleSaveStep() {
        this.canvas.current.saveCheckpoint()
    }

    handleStepClick(stepIncrease) {
        if (stepIncrease > 0) {
            this.canvas.current.nextCheckpoint()
        } else {
            this.canvas.current.previousCheckpoint()
        }
    }


    handleCanvasOnChange() {
        this.setState({
            canSaveCheckpoint: this.canvas.current.canSaveCheckpoint(),
            canUndo: this.canvas.current.lines.length > 0,
            canRewindCheckpoint: this.canvas.current.state.currentCheckpointIndex > 0,
            canForwardCheckpoint: this.canvas.current.state.currentCheckpointIndex < this.canvas.current.state.checkpoints.length,
            savedCheckpointCount: this.canvas.current.state.checkpoints.length,
            currentCheckpointIndex: this.canvas.current.state.currentCheckpointIndex
        })
    }


    handleUndoClick() {
        if (this.canvas.current) {
            const lastCheckpoint = this.canvas.current.state.checkpoints[this.canvas.current.state.checkpoints.length - 1]
            const isUndoingFromCurrentStep = this.canvas.current.state.checkpoints.length === 0 || this.canvas.current.lines.length > lastCheckpoint;
            if (isUndoingFromCurrentStep) {
                this.canvas.current.undo();
            } else {
                this.setState({
                    showConfirmUndo: true
                })
            }
        }
    }

    handleUndoFullStep() {
        this.setState({
            showConfirmUndo: false
        }, () => {

            this.canvas.current.unsaveCheckpoint()
            this.canvas.current.undo();
        })
    }

    handleCancelUndo() {
        this.setState({
            showConfirmUndo: false
        })
    }


    formOnChange() {
        this.setState({
            canSave: this.form.current.state.canSave
        })
    }

    toggleLazyGuide() {
        this.setState(prevState => ({
            lazyRadius: prevState.lazyRadius === 0 ? this.getLazyRadius() : 0
        }))
    }

    renderCanvas() {
        if (this.state.isLoadingSteps) {
            return <>Loading steps data...</>
        } else if (this.state.hasLoadingStepsError) {
            return <>Something went wrong while fetching artwork tutorial steps.</>
        }
        console.log(this.state.isLoading, this.state.hasLoadingError, !this.state.showCanvas)
        if (this.state.isLoading || this.state.hasLoadingError || !this.state.showCanvas) {
            return <>Loading...</>
        }

        const size = window.screen.width > 600 ? 600 : 380;

        return <>
            <Col md={12} lg={3} className="mb-3">
                <TutorialEditorForm
                    tutorialId={this.state.tutorialId} ref={this.form}
                    onChange={this.formOnChange.bind(this)}/>
            </Col>
            <Col md={7} lg={6} className="text-center ml-auto" ref={this.canvasContainerRef}>
                <CanvasWithCheckpoints
                    lazyRadius={this.state.lazyRadius}
                    brushRadius={3}
                    saveData={this.state.saveData}
                    checkpoints={this.state.initialCheckpoints}
                    disabled={!this.state.isRecording || this.state.savedCheckpointCount > this.state.currentCheckpointIndex}
                    canvasWidth={size}
                    canvasHeight={size}
                    imgSrc={this.state.traceImage}
                    ref={this.canvas}
                    onChange={this.handleCanvasOnChange.bind(this)}
                    loadTimeOffset={0}
                    immediateLoading={true}
                />
            </Col>
            <Col md={5} lg={3}>
                {this.renderCanvasControls()}
            </Col>
        </>;
    }

    render() {
        const previewUrl = (this.tutorialApiClient ? this.tutorialApiClient.baseURL: '') +
            '/' + this.state.tutorialId + '/html_steps/?show_buttons=1';
        return <Container fluid className="min-vh-100 main-container">

            <Navbar variant="dark" expand="lg" sticky="top">
                <Navbar.Brand href="">
                    {this.state.tutorialId ? "Edit Artwork Tutorial" : "Add Artwork Tutorial"}
                </Navbar.Brand>
                <Button onClick={() => this.props.history.goBack()}
                        className="ml-auto btn draweroo-btn mt-0">Cancel</Button>
            </Navbar>
            <div className="main-content">
                <ConfirmDialog
                    show={this.state.showConfirmUndo}
                    handleClose={this.handleCancelUndo.bind(this)}
                    handleConfirm={this.handleUndoFullStep.bind(this)}
                    title={"Undo a saved step"}
                    body={"Are you sure you want to undo? This will remove the the previous saved step."}
                />
                <ProgressDialog
                    show={this.state.showSaveProgress}
                    title={"Saving Artwork Tutorial"}
                    progress={this.state.saveProgress}
                    progressText={this.state.saveProgressText}
                    handleClose={this.state.saveProgress >= 99 && this.handleCloseProgress.bind(this)}
                    additionalFooters={<a
                        href={previewUrl}
                        rel="noopener noreferrer"
                        target="_blank">
                        Preview
                    </a>}
                />

                <Row>
                    {this.renderCanvas()}
                </Row>
            </div>
        </Container>
    }

    renderCanvasControls() {
        const buttonErrors = [];
        if (this.form.current && !this.form.current.state.form.name) {
            buttonErrors.push("Please provide an artwork tutorial name.")
        }
        if (this.form.current && !this.state.tutorialId && !this.form.current.state.coverImageFile) {
            buttonErrors.push("Please provide a cover image.")
        }
        const buttonErrorMessage = buttonErrors.join(' ')
        const currentStep = this.state.currentCheckpointIndex;

        if (this.state.isRecording) {
            return <React.Fragment>
                <div>
                    <FormCheck
                        id="lazy-guide-switch"
                        type="switch" checked={this.state.lazyRadius > 0} label="Lazy Guide"
                        onChange={this.toggleLazyGuide.bind(this)}
                    />
                </div>
                <div>
                    <Image src={undoImage} fluid id="undo-img" onClick={this.handleUndoClick.bind(this)}/>
                    <Button
                        className="draweroo-btn"
                        disabled={!this.state.canSaveCheckpoint}
                        onClick={this.handleSaveStep.bind(this)}>Save Step</Button>
                </div>
                <div>
                    <small className="text-black-50">
                        Hint: press <kbd style={{opacity:"50%"}}>CTRL</kbd>
                        +<kbd style={{opacity:"50%"}}>Z</kbd> to undo
                    </small>
                </div>
                <div>
                    <p id="steps-container">
                        Saved Steps: {this.state.savedCheckpointCount}
                        <br/>
                        {this.state.currentCheckpointIndex === this.state.savedCheckpointCount ? "Editing" : "Reviewing"} Step {currentStep + 1}
                    </p>
                </div>
                <div>
                    <p className="text-center mb-0 mt-4 font-weight-bold">Review Recorded Strokes</p>
                    <ButtonGroup>
                        <Button
                            disabled={!this.state.canRewindCheckpoint}
                            onClick={this.handleStepClick.bind(this, -1)}
                            className="draweroo-btn">
                            Previous
                        </Button>
                        <Button
                            disabled={!this.state.canForwardCheckpoint}
                            onClick={this.handleStepClick.bind(this, 1)}
                            className="draweroo-btn">Next</Button>
                    </ButtonGroup>

                    <Button
                        disabled={!this.state.canSave} title={buttonErrorMessage}
                        className="draweroo-btn btn-cta" onClick={this.handleSaveClick.bind(this)}>
                        Save Artwork Tutorial
                    </Button>
                </div>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <Button className="draweroo-btn" onClick={this.handleStartRecordingClick.bind(this)}>
                    Start Recording Strokes
                </Button>
                <label className="btn btn-primary draweroo-btn">
                    Upload Image to Trace <input type="file" hidden onChange={this.handleTraceImage.bind(this)}/>
                </label>
            </React.Fragment>
        }
    }

    handleTraceImage(event) {
        this.setState({
            traceImage: URL.createObjectURL(event.target.files[0])
        }, () => {
            this.canvas.current.drawImage()
        })
    }

    handleStartRecordingClick() {
        this.setState({
            isRecording: true
        }, () => {
        })
    }

    handleSaveClick() {
        // TODO: add validation
        // TODO: handle any errors on saving
        this.saveTutorial()
    }

    saveTutorial() {
        const _this = this;
        _this.setState({showSaveProgress: true}, () => {
            _this.form.current.save((progressPercent, progressMessage) => {
                _this.setState({
                    saveProgress: Math.round(progressPercent * 85),
                    saveProgressText: progressMessage
                })
            }, () => {
                _this.saveSteps()
            }, tutorialId => {
                this.setState({tutorialId: tutorialId})
            })
        })
    }

    saveSteps() {
        const _this = this;

        const stepsApiConfig = {
            onUploadProgress: function (progressEvent) {
                _this.setState({
                    saveProgress: 85 + Math.round((progressEvent.loaded * 15) / progressEvent.total),
                    saveProgressText: (progressEvent.loaded / progressEvent.total) >= 0.99 ? "All changes saved." : "Saving steps..."
                })
            }
        };


        const tutorialUrl = '/' + _this.state.tutorialId + '/';

        _this.tutorialApiClient.post(
            tutorialUrl + 'data/',
            {
                save_data: JSON.parse(_this.canvas.current.getSaveData()),
                checkpoints: _this.canvas.current.state.checkpoints
            },
            stepsApiConfig
        ).then(res => {
            console.log(res);
        }).catch(err => console.error(err))
    }
}

export default withRouter(TutorialEditor)