import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ApiClient from "../../base/ApiClient";
import {ProgressBar} from "react-bootstrap";
import {Chart} from "react-google-charts";
import dateFormat from 'dateformat'

class SearchWordCloud extends React.PureComponent {
    state = {
        words: [],
        isLoading: true,
        isLoaded: false
    }

    api = new ApiClient('tutorial')

    componentDidMount() {
        this.setState({isLoading: true, isLoaded: false}, () => {
            this.api.get('/search_wordcloud/', {params: this.props.params ? this.props.params: {}}).then(response => {
                this.setState({words: response.data, isLoaded: true})
            }).finally(() => {
                this.setState({isLoading: false})
            })
        })
    }

    render() {
        if (this.state.isLoading) {
            return "Loading..."
        }
        if (this.state.isLoaded && this.state.words.length === 0) {
            return "No data"
        }
        return <ReactWordcloud words={this.state.words} options={{
            rotations: 0,
            fontFamily: 'Questrial',
            fontSizes: [16, 64]
        }}/>
    }
}


class CategoryLineChart extends React.PureComponent {
    api = new ApiClient('category')
    state = {
        categorySeriesByTimeById: {},
        categoryNameById: {},
        categoryIdStatus: {}
    }

    loadCategoryId(categoryId) {
        this.api.get('/' + categoryId + '/insight/').then(response => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    categoryNameById: {
                        ...prevState.categoryNameById,
                        [categoryId]: response.data.name
                    },
                    categorySeriesByTimeById: {
                        ...prevState.categorySeriesByTimeById,
                        ...response.data.result.reduce((accumulator, row) => {
                            const row_date = row[0];
                            const row_value = row[1];
                            accumulator[row_date] = {
                                ...accumulator[row_date],
                                [categoryId]: row_value
                            };
                            return accumulator;
                        }, {...prevState.categorySeriesByTimeById})
                    }
                }
            })
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.categoryIds) {
            const newCategoryIds = nextProps.categoryIds.filter(categoryId => !this.state.categoryIdStatus.hasOwnProperty(categoryId));
            this.setState(prevState => ({
                ...prevState,
                categoryIdStatus: {
                    ...prevState.categoryIdStatus,
                    ...newCategoryIds.reduce((accumulator, currentValue) => {
                        accumulator[currentValue] = 0;
                        return accumulator;
                    }, {})
                }
            }), () => {
                newCategoryIds.forEach(categoryId => {
                    this.loadCategoryId(categoryId);
                })
            })
        }
    }
    
    render() {
        const monthToNowDateList = [];
        const now = new Date();
        const dayDuration = 24*60*60*1000;
        let currentDateTime = new Date(new Date().getTime() - (31 * 24 * 60 * 60 * 1000)) // 31 days ago
        while (currentDateTime <= now) {
            monthToNowDateList.push(dateFormat(currentDateTime, 'yyyy, mm, dd'))
            currentDateTime = new Date(currentDateTime.getTime() + dayDuration)
        }

        const categoryIdsToDisplay = Object.keys(this.state.categoryNameById).filter(categoryId => this.props.categoryIds.indexOf(parseInt(categoryId)) > -1)
        return <Chart
            chartType='LineChart'
            data={[
                ["Date", ...categoryIdsToDisplay.map(categoryId => this.state.categoryNameById[categoryId])],
                ...monthToNowDateList.map(dateValue => {
                    return [
                        new Date(dateValue),
                        ...categoryIdsToDisplay.map(categoryId => {
                            if (!this.state.categorySeriesByTimeById[dateValue]) {
                                return 0
                            }
                            const rawVal = this.state.categorySeriesByTimeById[dateValue][categoryId];
                            if (rawVal === undefined) {
                                return 0
                            }
                            return rawVal;
                        })]
                })
            ]}
            columns={[
                {type: 'date', label: 'Date'},
                ...categoryIdsToDisplay.map(categoryId => ({type: 'number', label: this.state.categoryNameById[categoryId]}))
            ]}
            options={{selectionMode: 'multiple', aggregationTarget: 'category', focusTarget: 'category'}}
        />

    }
}


class CategoryInsight extends React.PureComponent {
    api = new ApiClient('category')

    state = {
        isLoading: true,
        isLoaded: false,
        categoryCounts: [],
        selectedCategories: []
    }

    componentDidMount() {
        this.setState({isLoading: true, isLoaded: false}, () => {
            this.api.get('insights').then(response => {
                this.setState({
                    isLoaded: true,
                    categoryCounts: [...response.data.result]
                })
            }).finally(() => {
                this.setState({isLoading:false})
            })
        })
    }

    toggleCategoryCheckbox(categoryId) {
        this.setState(prevState => {
            if (prevState.selectedCategories.indexOf(categoryId) > -1) {
                // uncheck it
                return {
                    ...prevState,
                    selectedCategories: [...prevState.selectedCategories.filter(cat => cat !== categoryId)]
                }
            } else {
                return {
                    ...prevState,
                    selectedCategories: [
                        ...prevState.selectedCategories,
                        categoryId
                    ]
                }
            }
        })
    }

    renderContent() {

        if (this.state.isLoading) {
            return <Row><Col>Loading...</Col></Row>
        }
        if (!this.state.isLoading && !this.state.isLoaded) {
            return <Row><Col>Something went wrong.</Col></Row>
        }

        const maxValue = Math.max(...this.state.categoryCounts.map(row => row.value))

        return <Row>
            <Col sm={2} xs={12}>
                {this.state.categoryCounts.map(row => <Row onClick={this.toggleCategoryCheckbox.bind(this, row.id)}>
                    <Col><input type='checkbox' checked={this.state.selectedCategories.indexOf(row.id) > -1}/> {row.name}</Col>
                    <Col>
                        <ProgressBar max={maxValue} now={row.value} label={<span>{row.value} hits</span>}/>
                    </Col>
                </Row>)}
            </Col>
            <Col sm={10} xs={12}>
                <CategoryLineChart categoryIds={this.state.selectedCategories}/>
            </Col>
        </Row>
    }

    render() {
        return <div className={"border mt-4 my-5 p-2 row"}>
            <Col>
            <Row className="text-center">
                <Col>
                    <h4>Category Impressions (last 30 days)</h4>
                </Col>
            </Row>
            {this.renderContent()}
            </Col>
        </div>
    }
}

export default class Insights extends React.PureComponent {


    render() {
        return <Container fluid className="min-vh-100 main-container" id="main-container">
            <Navbar variant="dark" expand="lg" sticky="top">
                <Navbar.Brand href="">FCM Messages</Navbar.Brand>
                <NavLink className="btn draweroo-btn ml-auto mt-0" to='/'>Back</NavLink>
            </Navbar>

            <div className="main-content">
                <Row>
                    <Col className="border mx-1 py-1">
                        <div className="text-center pt-2">
                            <h4 className="text-danger">Search Words (Without Results, Last 30 Days)</h4>
                        </div>
                        <div  className="text-center">
                            <SearchWordCloud params={{has_result: false}}/>

                        </div>
                    </Col>

                    <Col className="border mx-1 py-1">
                        <div className="text-center pt-2">
                            <h4 className="text-success">Search Words (With Results, Last 30 Days)</h4>
                        </div>
                        <div className="text-center">
                            <SearchWordCloud params={{has_result: true}}/>

                        </div>
                    </Col>
                </Row>
                <CategoryInsight/>
            </div>
        </Container>
    }
}
