import React from 'react';
import PropTypes from 'prop-types';
import ApiClient from "../../base/ApiClient";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment, {isMoment} from "moment";


class FormEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            updatedData: {},
            itemId: this.props.itemId,
            errors: {}
        }
    }

    componentDidMount() {
        if (this.state.itemId && !this.props.dontLoad) {
            this.loadData(this.state.itemId)
        }
    }

    loadData(id) {
        this.props.api.getItem(id).then(response => {
            this.setState(prevState => ({...prevState, data: {...response.data}, updatedData: {}}))
        })
    }

    saveData(e) {
        if (e) {
            e.preventDefault();
        }
        if (!this.state.itemId) {
            this.props.api.create(this.state.updatedData).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    data: {...response.data},
                    updatedData: {},
                    itemId: response.data.id
                }), () => {
                    this.props.onSave()
                    this.props.onSetId && this.props.onSetId(this.state.itemId)
                })
            }).catch((error) => {
                if (error.response) {
                    this.setState({errors: {...error.response.data}})
                }
            })
        } else {
            const updateAction = this.props.method === 'PUT' ?
                this.props.api.updateItem.bind(this.props.api) : this.props.api.patchItem.bind(this.props.api);
            updateAction(this.state.itemId, this.state.updatedData).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    data: {...response.data},
                    updatedData: {},
                    itemId: response.data.id
                }), () => {
                    this.props.onSave()
                })
            }).catch((error) => {
                if (error.response) {
                    this.setState({errors: {...error.response.data}})
                }
            })
        }
    }

    onFieldChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.updateFieldDate(name, value)
    }

    updateFieldDate(name, value) {
        this.setState(prevState => ({
            ...prevState,
            updatedData: {
                ...prevState.updatedData,
                [name]: value
            }
        }))
    }

    renderFieldSchema(field) {
        const value = this.state.updatedData.hasOwnProperty(field.name) ? this.state.updatedData[field.name] : this.state.data[field.name];
        const error = this.state.errors[field.name] ? <Form.Control.Feedback type="invalid">
            {this.state.errors[field.name]}
        </Form.Control.Feedback>: <></>;
        let formControl;
        if (field.type && field.type === 'datetime') {
            const now = new Date();
            now.setHours(0)
            now.setMinutes(0)
            now.setSeconds(0)
            now.setMilliseconds(0)
            formControl = <>
                <Datetime
                    onChange={(date) => {
                        this.updateFieldDate(field.name, date)
                    }}
                    value={isMoment(value) ? value : moment(value, 'YYYY-MM-DDThh:mm:ssZ')}
                    isValidDate={(currentDate, selectedDate) => {
                        return currentDate >= now
                    } }
                />
                <Form.Control
                    name={field.name}
                    type={'hidden'}
                    isInvalid={!!this.state.errors[field.name]}
                />
            </>
        } else {
            formControl = <Form.Control
                name={field.name}
                as={field.type === 'textarea' ? 'textarea': 'input'}
                type={field.hasOwnProperty('type') ? field.type: 'input'}
                value={value ? value : ''}
                isInvalid={!!this.state.errors[field.name]}
                onChange={this.onFieldChange.bind(this)}
            />
        }

        return <Form.Group key={field.name}>
            <Form.Label>{field.label}</Form.Label>
            {formControl}
            {error}
        </Form.Group>
    }

    renderButtons() {
        if (this.props.hideButtons) {
            return <></>
        } else {
            return <>
                <Button type='button' onClick={this.saveData.bind(this)}>Save</Button>
                <Button type='button' onClick={() => {this.props.onCancel()}}>Cancel</Button>
            </>
        }
    }

    render() {
        const error = this.state.errors['non_field_errors'] ? <Form.Text>
            {this.state.errors['non_field_errors']}
        </Form.Text>: <></>;

        return <Form onSubmit={this.saveData.bind(this)}>
            {this.props.schema.map(field => this.renderFieldSchema(field))}
            {error}
            {this.renderButtons()}
        </Form>
    }
}

FormEditor.propTypes = {
    itemId: PropTypes.number,
    api: PropTypes.instanceOf(ApiClient).isRequired,
    schema: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onSetId: PropTypes.func,
    hideButtons: PropTypes.bool
}

export default FormEditor;