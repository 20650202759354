import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css'

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

Sentry.init({
    dsn: "https://50bd62e2600b43f7a3f2b2113e2026bf@o102310.ingest.sentry.io/5431093",
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
