import React from 'react';
import './App.css';
import {clearAuthTokens, isLoggedIn} from "axios-jwt";
import Login from "./components/Login";
import ContributorDashboard from "./components/ContributorDashboard";
import TutorialEditor from "./components/TutorialEditor";

import {HashRouter, Route, Switch} from 'react-router-dom';
import PrivateRoute from "./components/PrivateRoute";
import axios from 'axios';
import ApiClient from "./base/ApiClient";
import CategoryList from "./components/admin/CategoryList";
import ContributorList from "./components/admin/ContributorList";
import BottomNavbar from "./components/elements/BottomNavbar";
import Settings from "./components/admin/Settings";
import Fcm from "./components/admin/Fcm";
import {ToastContainer} from "react-toastify";
import Insights from "./components/admin/Insights";


class App extends React.Component {
    state = {
        isLoggedIn: null,
        userInfo: ''
    }
    userApi = null;

    componentDidMount() {
        this.handleUpdateLoginStatus()
        this.addHandlerForAuthenticationError()
        this.userApi = new ApiClient('me');
    }

    handleUpdateLoginStatus() {
        this.setState({isLoggedIn: isLoggedIn()}, () => {
            if (this.state.isLoggedIn) {
                this.userApi.get('/').then(response => {
                    this.setState({userInfo: response.data})
                }).catch( error => {
                    if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
                        this.doLogout()
                    }
                })
            }
        })
    }

    addHandlerForAuthenticationError() {
        axios.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, error => {
            if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
                this.doLogout()
                return Promise.reject(error);
            }
        });
    }

    doLogout() {
        clearAuthTokens();
        this.setState({isLoggedIn: false})
    }

    renderRoutes() {
        return <>
            <PrivateRoute path="/" exact>
                <ContributorDashboard isAdmin={this.state.userInfo.is_super_user}/>
            </PrivateRoute>
            <PrivateRoute path="/tutorial/:tutorialId?">
                <TutorialEditor/>
            </PrivateRoute>

            <PrivateRoute path="/admin/settings">
                <Settings/>
            </PrivateRoute>

            <PrivateRoute path="/categories" exact>
                <CategoryList/>
            </PrivateRoute>
            <PrivateRoute path="/contributors" exact>
                <ContributorList/>
            </PrivateRoute>
            <PrivateRoute path="/admin/fcm" exact>
                <Fcm/>
            </PrivateRoute>
            <PrivateRoute path="/admin/insights" exact>
                <Insights/>
            </PrivateRoute>

            <BottomNavbar
                displayName={this.state.userInfo.display_name}
                showAdmin={this.state.userInfo.is_super_user}
                doLogout={this.doLogout.bind(this)} />
        </>
    }

    render() {
        return <HashRouter>
            <Switch>
                <Route path="/login" exact>
                    <Login onLoginSuccess={this.handleUpdateLoginStatus.bind(this)}/>
                </Route>
                {this.renderRoutes()}
                <Route>
                    Loading...
                </Route>
                <ToastContainer/>
            </Switch>
        </HashRouter>
    }

}

export default App;
