import React from 'react';
import Image from "react-bootstrap/Image";
import {Link} from "react-router-dom";
import {TutorialImpressionsModal} from "./admin/TutorialImpressions";

export default class ArtWorkListItem extends React.PureComponent {
    state = {
        isPublishChanging: false,
        isFeatureChanging: false,
        showImpressions: false
    }

    handlePublishClick() {
        this.setState({isPublishChanging: true}, () => {
            this.props.apiClient.post('/' + this.props.tutorialData.id + '/publish/', {}).then(response => {
                this.props.tutorialOnChange(response.data)
            }).finally(() => {
                this.setState({isPublishChanging: false})
            })
        })
    }

    handleUnpublishClick() {
        this.setState({isPublishChanging: true}, () => {
            this.props.apiClient.delete('/' + this.props.tutorialData.id + '/publish/', {}).then(response => {
                this.props.tutorialOnChange(response.data)
            }).finally(() => {
                this.setState({isPublishChanging: false})
            })
        })
    }

    handleFeatureClick() {
        this.setState({isFeatureChanging: true}, () => {
            this.props.apiClient.post('/' + this.props.tutorialData.id + '/feature/', {}).then(response => {
                this.props.tutorialOnChange(response.data)
            }).finally(() => {
                this.setState({isFeatureChanging: false})
            })
        })
    }

    handleUnfeatureClick() {
        this.setState({isFeatureChanging: true}, () => {
            this.props.apiClient.delete('/' + this.props.tutorialData.id + '/feature/', {}).then(response => {
                this.props.tutorialOnChange(response.data)
            }).finally(() => {
                this.setState({isFeatureChanging: false})
            })
        })
    }

    handlePreviewClick() {
        this.props.apiClient.post('/' + this.props.tutorialData.id + '/set_preview/')
    }

    renderContributor() {
        if (this.props.isAdmin) {
            return <p><strong>
                Contributor: <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={
                    () => {
                        this.props.selectContributor(this.props.tutorialData.contributor_name, this.props.tutorialData.contributor_id);
                    }
                }>
                    {this.props.tutorialData.contributor_name} <i className="fa fa-search"/>
                </span>
            </strong></p>
        }
    }

    render() {
        const hasPermissionEditPublished = this.props.isAdmin;
        const hasPermissionPublish = this.props.isAdmin;
        const statusItems = [
            <span key={this.props.tutorialData.id + '-is_published'}>
                {this.props.tutorialData.is_published ? " Published" : " In-Progress"}
                {this.props.tutorialData.is_featured && " (Featured)"}
            </span>
        ];
        if (!this.props.tutorialData.is_published || hasPermissionEditPublished) {
            statusItems.push(<span key={this.props.tutorialData.id + '-edit'}>
                <Link to={`/tutorial/${this.props.tutorialData.id}`}>Edit</Link>
            </span>)
        }
        if (hasPermissionPublish) {
            if (this.state.isPublishChanging) {
                statusItems.push(<span className="spinner-border" role="status" aria-hidden="true"
                                       key={this.props.tutorialData.id + '-spinner'}/>)
            } else if (this.props.tutorialData.is_published) {
                statusItems.push(<span className="action" key={this.props.tutorialData.id + '-unpublish'}
                                       onClick={this.handleUnpublishClick.bind(this)}>Un-Publish</span>)
            } else {
                statusItems.push(<span className="action" onClick={this.handlePublishClick.bind(this)}
                                       key={this.props.tutorialData.id + '-publish'}>
                    Publish
                </span>)
            }

            if (this.state.isFeatureChanging) {
                statusItems.push(<span className="spinner-border" role="status" aria-hidden="true"
                                       key={this.props.tutorialData.id + '-spinner'}/>)
            } else if (this.props.tutorialData.is_featured) {
                statusItems.push(<span className="action" key={this.props.tutorialData.id + '-unfeature'}
                                       onClick={this.handleUnfeatureClick.bind(this)}>Remove featured</span>)
            } else {
                statusItems.push(<span className="action" onClick={this.handleFeatureClick.bind(this)}
                                       key={this.props.tutorialData.id + '-feature'}>
                    Feature
                </span>)
            }

            statusItems.push(<span
                key={this.props.tutorialData.id + '-impressions'}
                title="Impressions"
                className="action" onClick={() => this.setState({showImpressions: true})}>
                {/*<i className="fa fa-chart-bar"/>*/}Impressions
            </span> )
        }

        statusItems.push(
            <a
                className="action" key={this.props.tutorialData.id + '-preview'}
                onClick={this.handlePreviewClick.bind(this)}
                href={this.props.tutorialData.html_url + '?show_buttons=1'} target='_blank'
                rel="noopener noreferrer"
            >
                View
            </a>
        )

        const coverSrc = this.props.tutorialData.cover_thumbnail ? this.props.tutorialData.cover_thumbnail : this.props.tutorialData.cover;

        return <div className="card flex-row flex-nowrap mx-2 mt-2 mb-0 p-0">
            <div className="card-header border-0 p-0 tutorial-cover-image">
                <Image src={coverSrc} alt="" fluid className="align-middle"/>
            </div>

            <div className="card-block px-2 pt-2 flex-column">
                {this.state.showImpressions && <TutorialImpressionsModal
                    show={this.state.showImpressions}
                    tutorialTitle={this.props.tutorialData.name}
                    api={this.props.apiClient}
                    tutorialId={this.props.tutorialData.id}
                    handleClose={() => this.setState({showImpressions: false})}
                />}
                <p className="name">Name: {this.props.tutorialData.name}</p>
                <p className="categories text-truncate" title={this.props.tutorialData.categories.join(', ')}>
                    Categories: {this.props.tutorialData.categories.join(', ')}
                </p>
                <p className="tags  text-truncate" title={this.props.tutorialData.tags.join(', ')}>
                    Tag: {this.props.tutorialData.tags.join(', ')}
                </p>
                <p className="status"><strong>
                    Status:
                    {statusItems.reduce((acc, el) => acc === null ? [el] : [acc, ' | ', el], null)}
                </strong></p>
                {this.renderContributor()}


            </div>
        </div>
    }
}