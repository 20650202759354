import React from "react";
import {Chart} from "react-google-charts"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default class TutorialImpressions extends React.PureComponent {
    state = {
        data: [],
        isLoaded: false,
        isLoading: true
    }
    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.setState({isLoading: true}, () => {
            this.props.api.get('/' + this.props.tutorialId + '/impressions/').then(response => {
                this.setState({data: response.data.result, isLoaded: true})
            }).finally(() => {
                this.setState({isLoading: false})
            })
        })
    }
    render() {
        if (this.state.isLoading) {
            return "Loading data..."
        }
        if (this.state.isLoaded && this.state.data.length === 0) {
            return "No impressions"
        }

        return <Chart
            chartType="ColumnChart"
            columns={[
                {type: 'date', label: 'Date'},
                {type: 'number', label: 'Impressions'}
            ]}
            rows={this.state.data}
            />
    }
}


export class TutorialImpressionsModal extends React.PureComponent {
    render() {
        return <Modal show={this.props.show} onHide={this.props.handleClose} size="lg">
            <Modal.Header closeButton={!!this.props.handleClose}>
                <Modal.Title>Impressions for {this.props.tutorialTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TutorialImpressions api={this.props.api} tutorialId={this.props.tutorialId}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={this.props.handleClose}>
                    Ok
                </Button>
            </Modal.Footer>
        </Modal>
    }
}