import React, {forwardRef} from 'react'
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MaterialTable from 'material-table';
import ApiClient from "../../base/ApiClient";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Add, CheckCircleOutline, RadioButtonUncheckedOutlined, Send} from "@material-ui/icons";
import Modal from "react-bootstrap/Modal";
import FormEditor from "../elements/FormEditor";
import moment from "moment";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


export default class Fcm extends React.PureComponent {

    api = new ApiClient('fcm_message')

    constructor(props) {
        super(props);
        this.editor = React.createRef()
        this.table = React.createRef()
        this.state = {
            showEditor: false,
            editorId: null
        }
    }

    onSave() {
        this.setState({showEditor: false}, () => {
            this.table.current.onQueryChange()
        })
    }

    render() {
        return <Container fluid className="min-vh-100 main-container" id="main-container">
            <Navbar variant="dark" expand="lg" sticky="top">
                <Navbar.Brand href="">FCM Messages</Navbar.Brand>
                <NavLink className="btn draweroo-btn ml-auto mt-0" to='/'>Back</NavLink>
            </Navbar>

            <Modal show={this.state.showEditor} onHide={() => {this.setState({showEditor: false})}}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>FCM Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormEditor
                        ref={this.editor}
                        api={this.api}
                        schema={[
                            {label: 'Title', name: 'title'},
                            {label: 'Body', name: 'body', type: 'textarea'},
                            {label: 'Schedule', name: 'datetime_schedule', type: 'datetime'}
                        ]}
                        hideButtons={true}
                        onSave={this.onSave.bind(this)}
                        onCancel={() => {this.setState({showEditor: false})}}
                        itemId={this.state.editorId}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {this.editor.current.saveData()}}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={() => {this.setState({showEditor: false})}}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="main-content">
                <Row>
                    <Col>
                        <MaterialTable
                            tableRef={this.table}
                            title="FCM Messages"
                            actions={[
                                {
                                    icon: Add,
                                    tooltip: 'Add new FCM message',
                                    onClick: () => {
                                        this.setState({showEditor: true, editorId: null})
                                    },
                                    isFreeAction: true
                                },
                                rowData => ({
                                    icon: Edit,
                                    tooltip: 'Edit FCM message',
                                    onClick: (event, rowData) => {
                                        this.setState({showEditor: true, editorId: rowData.id})
                                    },
                                    disabled: rowData.is_sent
                                }),
                                rowData => ({
                                    icon: Send,
                                    tooltip: 'Send Now',
                                    onClick: (event, rowData) => {
                                        this.api.post('/' + rowData.id + '/send/').then(() => {

                                        }).catch(() => {

                                        }).finally(() => {
                                            setTimeout(() => {
                                                this.table.current.onQueryChange()
                                            }, 1000)
                                        })
                                    },
                                    disabled: rowData.is_sent
                                })
                            ]}
                            icons={tableIcons}
                            columns={[
                                {title: 'id',  field: 'id'},
                                {
                                    title: 'Is Sent',
                                    field:'is_sent',
                                    render: rowData => rowData.is_sent ? <CheckCircleOutline/>: <RadioButtonUncheckedOutlined/>
                                },
                                {title: 'Title', field:'title'},
                                {
                                    title: 'Schedule',
                                    field: 'datetime_schedule',
                                    render: rowData => moment(rowData.datetime_schedule, 'YYYY-MM-DDThh:mm:ssZ').format('LLL')
                                },
                                {
                                    title: 'Sent Date Time',
                                    field: 'datetime_sent',
                                    render: rowData => rowData.datetime_sent
                                        ? moment(rowData.datetime_sent, 'YYYY-MM-DDThh:mm:ssZ').format('LLL')
                                        : "Not yet sent"
                                }
                            ]}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    console.log("query", query)
                                    this.api.list({
                                        params: {
                                            page: query.page + 1,
                                            page_size: query.pageSize,
                                            search: query.search,
                                            ordering: query.orderBy && (query.orderDirection === 'desc'? '' : '-') + query.orderBy.field
                                        }
                                    }).then(result => {
                                        console.log(result.data, result)
                                        resolve({
                                            data: result.data.results,
                                            totalCount: result.data.count,
                                            page: result.data.current_page - 1,
                                        })
                                    });
                                })
                            }
                        />
                    </Col>
                </Row>
            </div>
        </Container>
    }
}