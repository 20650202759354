import React from 'react';
import {NavLink} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import ChangePassword from "./ChangePassword";


export default class BottomNavbar extends React.PureComponent {
    render() {
        const adminLinks = <>
            <NavLink to='/contributors'><i className="fa fa-users"/> <span className="d-none d-sm-inline">Contributors</span></NavLink>
            &nbsp; <span className="d-none d-sm-inline">| &nbsp;</span>
            <NavLink to='/categories'><i className="fa fa-boxes"/> <span className="d-none d-sm-inline">Categories</span></NavLink>
            &nbsp; <span className="d-none d-sm-inline">| &nbsp;</span>
            <NavLink to='/admin/settings'><i className="fa fa-cogs"/> <span className="d-none d-sm-inline">Settings</span></NavLink>
            &nbsp; <span className="d-none d-sm-inline">| &nbsp;</span>
            <NavLink to='/admin/fcm'><i className="fa fa-envelope"/> <span className="d-none d-sm-inline">FCM</span></NavLink>
            &nbsp; <span className="d-none d-sm-inline">| &nbsp;</span>
            <NavLink to='/admin/insights'><i className="fa fa-search"/> <span className="d-none d-sm-inline">Insights</span></NavLink>
        </>;

        return <>

            <Navbar variant="light" expand="lg" fixed="bottom">
                { this.props.showAdmin && adminLinks }
                <div className="ml-auto">
                    Welcome {this.props.displayName}! <ChangePassword/><span className="d-none d-sm-inline"> |&nbsp;</span>
                <button type="button" onClick={this.props.doLogout} className="btn btn-link">
                    <i className="fa fa-sign-out-alt"/><span className="d-none d-sm-inline"> Logout</span>
                </button>
                </div>
            </Navbar>
        </>
    }
}