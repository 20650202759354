import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../css/ArtWorkList.css';
import ApiClient from "../../base/ApiClient";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form"
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";

export default class ContributorList extends React.PureComponent {
    contributorApiClient;
    state = {
        contributors: [],
        highlightedContributor: '',
        data: {},
        isSaving: false,
        disabledContributorAction: []
    }

    componentDidMount() {
        this.contributorApiClient = new ApiClient('contributor');
        this.contributorApiClient.list().then(response => {
            this.setState({contributors: [...response.data]})
        })
    }

    handleContributorAdd() {
        this.setState({isSaving: true}, () => {
            this.contributorApiClient.create({...this.state.data}).then( response => {
                this.setState(prevState => ({
                    contributors: [...prevState.contributors, response.data],
                    data: {},
                    highlightedContributor: response.data.name,
                    isSaving: true
                }))
            })
        })
    }

    handleNewContributorDataChange(e) {
        const newValue = e.target.value;
        const name = e.target.name
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                [name]: newValue
            }
        }));
    }

    handleDeactivateContributor(id) {
        this.setState(prevState => ({
            ...prevState,
            disabledContributorAction: [...prevState.disabledContributorAction, id]
        }), () => {
            this.contributorApiClient.delete('/' + id + '/active/').then(response => {
                this.setState(prevState => ({
                    contributors: [...prevState.contributors.filter(o => o.id !== id), response.data],
                    disabledContributorAction: [...prevState.disabledContributorAction.filter(stateId => stateId !== id)]
                }))
            })
        })
    }

    handleActivateContributor(id) {
        this.setState(prevState => ({
            ...prevState,
            disabledContributorAction: [...prevState.disabledContributorAction, id]
        }), () => {
            this.contributorApiClient.post('/' + id + '/active/').then(response => {
                this.setState(prevState => ({
                    contributors: [...prevState.contributors.filter(o => o.id !== id), response.data],
                    disabledContributorAction: [...prevState.disabledContributorAction.filter(stateId => stateId !== id)]
                }))
            })
        })
    }

    renderContributorAction(o) {
        if (o.is_active) {
            return <Button size="sm" className={"float-right btn-danger"}
                           disabled={this.state.disabledContributorAction.indexOf(o.id) > -1}
                           onClick={this.handleDeactivateContributor.bind(this, o.id)}>
                <i className={"fa fa-trash"} /><span className="d-none d-sm-inline"> Deactivate</span>
            </Button>
        } else {
            return <Button size="sm" className={"float-right btn-success"}
                           disabled={this.state.disabledContributorAction.indexOf(o.id) > -1}
                           onClick={this.handleActivateContributor.bind(this, o.id)}>
                <i className='fa fa-thumbs-up' /><span className="d-none d-sm-inline"> Activate</span>
            </Button>
        }
    }

    render() {
        const upperContributorEmails = this.state.contributors.map(o => o.email.toUpperCase())
        const contributorList = this.state.contributors.sort((a, b) => {
            if (a.email > b.email) return 1;
            if (a.email < b.email) return -1;
            return 0
        }).map(o => <Col sm={12} md={6} xl={4} className="mb-2 ">
            <Card className={"contributor-list-item "} key={o.email}>
                <Card.Body>
                    {this.renderContributorAction(o)}
                    <p>{this.state.highlightedContributor === o ? <strong>{o.email}</strong> : o.email}</p>
                    {o.first_name} {o.last_name}
                </Card.Body>
                <Card.Footer>
                    <small>Published Artworks: {o.published_artworks} of {o.artworks}</small><br/>
                    <small>Last Login: {o.last_login ? o.last_login : 'Never'}</small>
                </Card.Footer>
            </Card>
        </Col>);

        const isNewContributorExisting = this.state.data.email && upperContributorEmails.indexOf(this.state.data.email.toUpperCase()) > -1;
        const isNewContributorOk = !this.state.data.email || isNewContributorExisting;

        return <Container fluid className="min-vh-100 main-container" id="main-container">

            <Navbar variant="dark" expand="lg" sticky="top">
                <Navbar.Brand href="">Contributors</Navbar.Brand>
                <NavLink className="btn draweroo-btn ml-auto mt-0" to='/'>Back</NavLink>
            </Navbar>

            <div className="main-content">
                <Row>
                    <Col><h3>Current Contributors</h3></Col>
                </Row>
                <Row>
                    { contributorList.length > 0 ? contributorList : 'No contributors yet!' }
                </Row>
                <Row>
                    <Col sm={6} md={4} className="m-auto pt-5">
                        <Card className="p-3">
                            <Card.Title>
                                Add New Contributor
                            </Card.Title>
                            <Card.Body className="m-auto">
                                <Form.Control
                                    size="lg" type="text" placeholder="contributor@domain.com"
                                    value={this.state.newContributorName} name="email"
                                    onChange={this.handleNewContributorDataChange.bind(this)}
                                    required
                                    isInvalid={isNewContributorOk}
                                    disabled={this.state.isSaving}
                                />

                                <Form.Control
                                    size="lg" type="text" placeholder="First Name"
                                    value={this.state.newContributorName} name="first_name"
                                    onChange={this.handleNewContributorDataChange.bind(this)}
                                    required
                                    disabled={this.state.isSaving}
                                />

                                <Form.Control
                                    size="lg" type="text" placeholder="Last Name"
                                    value={this.state.newContributorName} name="last_name"
                                    onChange={this.handleNewContributorDataChange.bind(this)}
                                    required
                                    disabled={this.state.isSaving}
                                />
                                <Button
                                    type="button"
                                    disabled={isNewContributorOk || this.state.isSaving}
                                    onClick={this.handleContributorAdd.bind(this)}
                                    className={isNewContributorExisting ? "btn-danger" : ''}
                                >
                                    {isNewContributorExisting ? "Existing contributor": "Add"}
                                </Button>

                            </Card.Body>

                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    }
}