import {setAuthTokens, useAuthTokenInterceptor, clearAuthTokens} from 'axios-jwt';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL
axios.defaults.baseURL = BASE_URL

export const authResponseToAuthTokens = (res) => {
    return {
        accessToken: res.access,
        refreshToken: res.refresh
    }
};

// login
export const login = async (params) => {
    const res = (await axios.post("/token/", params)).data;
    // save tokens to storage
    setAuthTokens(authResponseToAuthTokens(res));
};

const requestRefresh = (refresh) => {
    return new Promise((resolve, reject) => {
        // notice that this is the global axios instance.  <-- important
        axios.post('/token/refresh/', {
            refresh
        }).then(response => {
            if (response && response.data  && response.data.access) {
                resolve(response.data.access);
            } else {
                clearAuthTokens()
            }
        }, reject);
    });
};

const defaultAuthenticationErrorHandler = (error) => {
    return Promise.reject(error);
}


export default class ApiClient {
    baseURL = '';
    constructor(objectName, authenticationErrorHandler) {
        const axiosSettings = {
            baseURL: BASE_URL.replace(/\/$/, "") + '/' + objectName
        }
        this.baseURL = BASE_URL.replace(/\/$/, "") + '/' + objectName;

        let apiClient = axios.create(axiosSettings);

        if (!authenticationErrorHandler) {
            authenticationErrorHandler = defaultAuthenticationErrorHandler
        }


        apiClient.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, authenticationErrorHandler);

        useAuthTokenInterceptor(apiClient, { requestRefresh });  // Notice that this uses the apiClient instance
        this.client = apiClient;
    }

    // GENERIC API METHODS
    get(url, conf = {}) {
        return this.client.get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    head(conf = {}) {
        return this.client.head('/', conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    options(conf = {}) {
        return this.client.options('/', conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    post(url, data = {}, conf = {}) {
        return this.client.post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    delete(url, conf = {}) {
        return this.client.delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }


    put(url, data = {}, conf = {}) {
        return this.client.put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    patch(url, data = {}, conf = {}) {
        return this.client.patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }


    list(conf= {}) {
        return this.get('/', conf);
    }

    getItem(id, conf = {}) {
        return this.get('/' + id + '/', conf);
    }

    deleteItem(id, conf = {}) {
        return this.delete('/' + id + '/', conf)
    }

    create(data = {}, conf = {}) {
        return this.client.post('/', data, conf);
    }

    updateItem(id, data = {}, conf = {}) {
        return this.put('/' + id + '/', data, conf)
    }

    patchItem(id, data = {}, conf = {}) {
        return this.patch('/' + id + '/', data, conf);
    }
}
