import React from "react";
import Navbar from "react-bootstrap/Navbar";
import {NavLink} from "react-router-dom";

export default class ContributorNavbar extends React.PureComponent {
    render() {
        return <Navbar variant="dark" expand="lg" sticky="top">
            <Navbar.Brand href="">Contributor Dashboard</Navbar.Brand>

            <NavLink className="btn draweroo-btn ml-auto mt-0" to='/tutorial/'>New Tutorial</NavLink>
        </Navbar>
    }
}